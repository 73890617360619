import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';

export default function BedScreen(){
    return(
        <>
        <Header></Header>
        <Footer></Footer>
        </>
    )
}