import React from "react";
import Header from './Header';
import Footer from './Footer';
import secretaryImage from '../assets/images/secretary.jpg';
import campusImage from '../assets/images/thiravium-group-campus.jpg';

export default function SecretaryScreen(){
    return(

        <>
        <Header></Header>
        <section className="hero-banner-slider sql-ft" style={{backgroundImage: `url(${campusImage})`}} >
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-10">
							<div className="feature-box-list sql-ft ad-spl-ft hgt-box">
									<div className="list">
										<div className="lft">
											<img src={secretaryImage} className="img" alt="secretary"/>
										</div>
										<div className="rgt">
										<span>Secretary's Desk</span>
											<h5>"Innovation distinguishes between a leader and a follower."</h5>
											<h6>Dr. P. HEMALATHA MBBS.</h6>
											<p>(Secretary)</p>							
										</div>
									</div>
								</div>
						
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
					<div className="col-12 col-xl-12">
						<div className="cnt spl-cnt">
						    
							
								<p className="tt "><b className="w-auto">Dear all….!!!</b></p>
							<p>We are all living in the modern world. Innumerable advancements are being made in science and technology every day.</p>

<p>If we wish to succeed in this exceedingly competitive world of today we need to enhance our way of thinking and living.</p>

<p>I am thrilled to see so many former students seeking further academic degrees, and my heart rejoices when they acknowledge our Institution's part in their accomplishments. I believe that students' all-around growth will prepare them to meet the ever-increasing demands of modern life.</p>

<p>I ardently encourage all the students to take advantage of the opportunities provided by our Group of Institutions and actively engage in all the extra-curricular activities available. This participation demands that you become well-informed, therefore developing yourself as a pioneer and, ultimately, growing as responsible women.</p>

<p>Our journey is towards excellence. We invite you to be a part of that excellence by becoming an authentic member and sharing the exhilaration of the learning experience.</p>

<p>We wish to take this opportunity to welcome the students, academicians and the people who are interested in professional education at Thiravium Group of Institutions. May God bless you all. Thank You.</p>
</div>
	
						</div>
						
						
					</div>
					
					
				</div>
			
		</section>
        <Footer></Footer>
        </>
    )
}