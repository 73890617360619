import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import missionImage from '../assets/images/mission-icon.png';
import visionImage from '../assets/images/vision-icon.png';

export default function BscPhysicsScreen(){
    return(
        <>
        <Header></Header>
        <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Department of Physics</h1>	
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
						<div className="col-12 col-xl-12 col-sm-12">
								<div className="cnt spl-cnt">						    
									<p>The department of Physics was established in the year 2014. The department has well established laboratories equipped with sophisticated instruments, which fulfill the need of students. Our department plays a pivotal role in developing scientific temper among students. The department has dedicated and well qualified faculties having expertise in the diverse fields of Physics. The department aims at holistic development of students through academic excellence, employability and scientific skills.</p>
								</div>	
						</div>			
					</div>
					<div className="row">
						<div className="col-12 col-xl-4 col-sm-12">
							<div className="vision-bg ml-null">
								<div className="hd"> 
									<h3><img src={visionImage} alt="vision"/>Vision</h3>
								</div>
								<div className="cnt">
									<p>To build foundation for excellence and spur development of the institution as a premier institution by igniting and nurturing enthusiasm, interests and passion in the study of Physics in professional courses as a part of curriculum.</p>
								</div>
							</div>
						</div>
							<div className="col-12 col-xl-8 col-sm-12">							
							<div className="vision-bg ml-null">
								<div className="hd">
									<h3><img src={missionImage} alt="mission" />Mission</h3>
								</div>
								<div className="cnt">
									<ul> 
                                        <li>To awaken the young minds and discover their talents both in theory and in practical Physics, through dedication to teaching, commitment to students and innovative instructional methods like LCD Presentation, OHP/LCDs etc.</li>
                                        <li>To support the developmental activities of the college and make the department vibrant.</li>
                                        <li>To introduce pioneering programs in the department that will embrace heritage and values of the institution.</li>
                                        <li>To evolve strategies in the Department for continuous improvement.</li>
                                    </ul>
								</div>
							</div>
						</div>								
					</div>
				</div>
			
		</section>
		
        <Footer></Footer>
        </>
    )
}