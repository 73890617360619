import React from "react";
import Header from './Header';
import Footer from './Footer';
import cooImage from '../assets/images/coo.jpeg';
import campusImage from '../assets/images/thiravium-group-campus.jpg';
export default function CooScreen(){
    return(

        <>
        <Header></Header>
        <section className="hero-banner-slider sql-ft" style={{backgroundImage: `url(${campusImage})`}} >
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-10">
							<div className="feature-box-list sql-ft ad-spl-ft hgt-box">
									<div className="list">
										<div className="lft">
											<img src={cooImage} className="img" alt="coo" />
										</div>
										<div className="rgt">
											<span>Chief Operating Officer</span>
                                             <h5>"Education is NOT preparation for life; Education is life itself"</h5>
											<h6>Dr. H. Anwer Basha M.Sc., M.Phil., B.Ed., PGDCA., Ph.D.</h6>
											<p>(Chief Operating Officer)</p>					
										</div>
									</div>
								</div>
						
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
					<div className="col-12 col-xl-12">
						<div className="cnt spl-cnt">
						    
							
                        <p className="tt "><b className="w-auto">COO's Message</b></p>
						<p>At Thiravium , we don't believe in preaching, instead we allow our students to experience, explore and experiment through various experiences. We ensure that students have stronger roots to grow upon. When we mention education, it we aves a simple picture in our minds of a student learning and a teacher teaching, but education is beyond the classical paper-pencil-black board theory. It has evolved into the giant wheel that has many spokes such as personality management, time management, competing beyond boundaries etc. We, at Thiravium have strived hard to propagate our age old theory to impart quality education at affordable price to the able scholars who seek excellence in their pursuit for an indomitable career.</p>
                        <p>Along with the academic excellence and vast sports opportunities, we provide our students with an exposure to the realities of the world. We sculpt them into competitive, empathetic, optimistic individuals who strive to succeed in every challenge they are faced with. We aim at ensuring that our comprehensive development programs provide students with an international learning experience, while preserving our core Indian values.</p>
                        </div>
	
						</div>
						
						
					</div>
					
					
				</div>
			
		</section>
        <Footer></Footer>
        </>
    )
}