import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import missionImage from '../assets/images/mission-icon.png';
import visionImage from '../assets/images/vision-icon.png';

export default function BAEnglishScreen(){
    return(
        <>
        <Header></Header>
        <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Department of English</h1>		
							
							
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
						<div className="col-12 col-xl-12 col-sm-12">
								<div className="cnt spl-cnt">						    
									<p>The Department of English was started with the U.G Programme in 2013 and the P.G.Programme in 2015.It has experienced a significant growth over these years with the excellent faculty members, who are adequately qualified and experienced in their respective area of study. In addition to M.Phil. degree, a few teachers have got B.Ed.& M.Ed., degree which enables them to good at the pedagogical aspects of teaching. Along with their significant focus on academic performance faculty members train the students in communication skills to gain language competence and confidence. The Department conducts various activities such as conversation exchanges, dialogue writing, story telling and oratorical competitions under auspices of English Literary Association.</p>
								</div>	
						</div>			
					</div>
					<div className="row">
						<div className="col-12 col-xl-6 col-sm-12">
							<div className="vision-bg ml-null">
								<div className="hd"> 
									<h3><img src={visionImage} alt="vision"/>Vision</h3>
								</div>
								<div className="cnt">
									<ul>
									<li>Our graduates are well prepared for professional and graduate study and for lifelong learning. They pursue careers in education research, business, law, government and publishing.</li>
									<li>The proficiency in English of students to enable them to appreciate aesthetics of literature, quest excellence and understand society through experiences of life.</li>
									<li>The department of English assure that students encounter creativity crucial to English studies through theater productions, reading by professional writers and their own creative and critical production and presentation.</li>
									<li>The mainstay of the vision of the Department of English is to offer students adequate communication skills to prepare them for their professional needs in the globalized scenario prevalent today.</li>
									<li>The Department of English as part of a discipline with skills and abilities valuable in the business teaching, publishing or post graduate work.</li>
									</ul>
								</div>
							</div>
						</div>
							<div className="col-12 col-xl-6 col-sm-12">							
							<div className="vision-bg ml-null">
								<div className="hd">
									<h3><img src={missionImage} alt="mission" />Mission</h3>
								</div>
								<div className="cnt">
									<ul>
										<li>The Department has taken upon itself the mission of working resourcefully to impart for four skills of listening, speaking, reading and writing to students.</li>
    <li>The English Department strengthens the liberal education of students by developing a language and literacy, the value of critical reading and effective writing and the richness of literature past and present.</li>
    <li>To inspire curiosity of discovery for success in a rapidly changing world.</li>
    <li>To provide a conductive teaching learning, research and development environment where staff and students will interact and compete effectively.</li>
    <li>To enable the students to acquire communicative competence so that they make quality use of language.</li>
									</ul>
								</div>
							</div>
						</div>								
					</div>
				</div>
			
		</section>
		<section className="testimonial-box-bg custom-box-bg">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<h3 className="tt">Highlights:</h3>											
					</div>
				</div>
				<div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4>During the 2015, we received university rank I.</h4>									
								</div>
								<div className="counter">
									<h4>During the 2015, we received university rank IDuring the 2018, we received university rank II.</h4>									
								</div>
							</div>
						</div>												
					</div>
				</div>	
				<div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4>We have won number of prizes Intra and Intercollegiate programme.</h4>									
								</div>	
								<div className="counter">
									<h4>During the year 2018, we have received Rani Mangammal Scholarship.</h4>									
								</div>
							</div>
						</div>												
					</div>
				</div>
			</div>
		</section>
        <Footer></Footer>
        </>
    )
}