import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import principalImage from '../assets/images/nursing_principal.jpg';
import missionImage from '../assets/images/mission-icon.png';
import visionImage from '../assets/images/vision-icon.png';
import campusImage from '../assets/images/thiravium-group-campus.jpg';
import cycleImage from '../assets/images/cycle.jpg';
import teamImage from '../assets/images/team-spirit.jpg';
import analysicImage from '../assets/images/analysis.jpg';
import fieldImage from '../assets/images/field.jpg';
import libraryImage from '../assets/images/special-library.jpg';

export default function CollegeNursingScreen(){
    return(
        <>
          <Header></Header>
          <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">College of Nursing</h1>		
							
							
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="cnt spl-cnt">
						    
							<div className="feature-box-list sql-ft  hgt-box">
									<div className="list">
										<div className="lft">
											<img src={principalImage} className="img" alt="principal"/>
										</div>
										<div className="rgt">
											<h5>Dr. S. Kavitha M.Sc.(Nursing), Ph.D(Nursing)</h5>
											<p>(Principal)</p>							
										</div>
									</div>
								</div>
								<p className="tt "><b className="w-auto">Principal’s Message,</b></p>
								<p className="sb-tt">It gives me immense pleasure and a feeling of accomplishment to address you as the Principal of this prestigious institution. Since its inception in 2009, the college has been striving towards maintaining high academic standards and excellence in the field of Nursing Education. We are committed to providing our students with a wide variety of opportunities in order to help them attain their highest potentials. Our aim is to produce excellent professional nurses with exemplary stamina, courage, compassion and devotion to duty, who will make a name for themselves in the years to come.</p>

<p className="sb-tt">We have a dedicated team of faculty, striving hard to provide the students with the latest knowledge and skill that would help them to stand out in this fiercely competitive world. We demonstrate outstanding commitment to research excellence throughout the course. We address the global challenges and assure the high quality student education, experience and employability.</p>

<p className="sb-tt">Our campus is vibrant with energy, enthusiasm and activity. The environment is congenial for learning , and it is for you to take the full advantage of the opportunities and exploit your potentials. Achieving a more educated workforce is the need of the hour. I am confident that the Thiravium College of Nursing as a team works on this philosophy without losing the essence of nursing and will create a benchmark in nursing education in India as also overseas by ensuring a steady flow of trained professionals who meet the international standards in terms of quality of education and service provided.</p>

<p className="sb-tt">I welcome all the aspirants to join their hands with us for their academic journey to turn out as a successful nursing professional and wish you success in all your future endeavors.</p>
							</div>
	
						</div>
						<div className="col-12 col-xl-4 col-sm-12">
							<div className="vision-bg">
								<div className="hd">
									<h3><img src={missionImage} alt="mission" />Mission</h3>
								</div>
								<div className="cnt">
									
									<p className="sb-tt">
										Our mission is to provide quality patient care based on our strong commitment to provide education, research, innovation and collaboration.
									</p>
								</div>
							</div>	
							<div className="vision-bg">
								<div className="hd">
									<h3><img src={visionImage} alt="vision"/>Vision</h3>
								</div>
								<div className="cnt">
									<p>Our Vision is to develop leaders in nursing and health care whose actions, innovations and voices strengthen and transform the health of individuals and the community at large.</p>
								</div>
							</div>
						</div>
						
					</div>
					
					
				
			</div>
		</section>
		
		<section className="hero-about-us spl-hgt">
			<div className="container-fluid">
				
				<div className="row">	
					<div className="col-xl-4">
						<img src={cycleImage} className="img-tg" alt="cycle" />
					</div>	
					
					<div className="col-12 col-xl-8">
						<div className="cnt">
						    <h2 className="tt">Physical Facilities</h2>
							<p className="sb-tt">
							Our college has excellent infrastructure with spacious classNamerooms comprising of adequate seating arrangements, ventilation, lighting facilities and physical environment.Preclinical laboratories for:</p>
							<ul className="sb-tt">																
								<li>Anatomy</li>
								<li>Nursing foundation</li>
								<li>Community Health Nursing</li>
								<li>Paediatric Nursing</li>
								<li>Obstetrics and Gynecology</li>
								<li>Computer Lab</li>
								<li>Pre Clinical Lab</li>								
							</ul>
							<p className="sb-tt">Well accommodated hostel with adequate rooms and mess. An Auditorium to celebrate special occasions like Christmas, college day, Lamp Lighting etc.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		
		<section className="hero-about-us spl-hgt ad-spl-ht">
			<div className="container-fluid">				
				<div className="row">						
					<div className="col-12 col-xl-3">
						<div className="cnt">
							 <h2 className="tt">College Library</h2>
							<img src={libraryImage} className="img-tg" alt="library " />						   
							<p className="sb-tt">
							Our college library has adequate number of books and journals. Approximately more than 4000 books, International and National journals are available in our library. Our library also has good seating arrangements and a proficient librarian.</p>
						</div>
					</div>
					<div className="col-12 col-xl-3">
						<div className="cnt">
							 <h2 className="tt">Curricular Activities</h2>
							<img src={fieldImage} className="img-tg" alt="field"/>						   
							<p className="sb-tt">
							In order to enhance and motivate student in the progressive process of learning, various efforts are being undertaken by the staff. These efforts include:</p>
							<ul className="sb-tt">																
								<li>Taking className using AV Aids like LCD, OHP, Models etc.</li>
								<li>Experts' Visits are planned and implemented.</li>
							</ul>							
						</div>
					</div>
					<div className="col-12 col-xl-3">
						<div className="cnt">
							 <h2 className="tt">Clinical Experience</h2>
							<img src={analysicImage} className="img-tg" alt="analysic"/>						   
							<p className="sb-tt">
							We select and provide rich clinical field experience which promotes nursing skills, attitude and knowledge about client-care. Our Students gain their clinical experience in Periyakulam Government Hospital. Students are also posted in Specialty hospitals </p>
						</div>
					</div>
					<div className="col-12 col-xl-3">
						<div className="cnt">
							 <h2 className="tt">Community Field Experience</h2>
							<img src={teamImage} className="img-tg" alt="team"/>						   
							<p className="sb-tt">
							The students are posted in Periyakulam (Vadugapatti) Primary Health Centre for Urban posting and Melmangalam Primary Health center for rural experience. Our Community experience extends to all age groups various from individual health needs including health assessment, screening, referral, health teaching and follow up. And extending to the community as a whole in terms of mass health education, health camps and so on.</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-xl-12">
						<div className="cnt">
							 <h2 className="tt">Conclusion</h2>												   
							<p className="sb-tt">
							Thiravium College of Nursing has shouldered this responsibility since 2009 by preparing qualified nurses to assume responsibilities as professional and competent nurses and midwives to provide promotive, preventive, curative and rehabilitative services. This achievement was possible only with the support and cooperation of the management, all the faculties and students.</p>
						</div>
					</div>					
				</div>
			</div>
		</section>
          <Footer></Footer>
        </>
    )
}