import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import missionImage from '../assets/images/mission-icon.png';
import visionImage from '../assets/images/vision-icon.png';

export default function BscZoologyScreen(){
    return(
        <>
        <Header></Header>
        <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Department of zoology</h1>	
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				    <div className="row">					
						<div className="col-12 col-xl-12 col-sm-12">
								<div className="cnt spl-cnt">						    
									<p>Zoology is a foundation stone for the modern biological sciences such as molecular biology, genetic engineering, bio-technology, bio-informatics and micro biology. The department of zoology was started in 2016 and is one of the leading earliest departments in our college. It established the club that is ZOO LATRY club for the improvement and motivates the students and guidance. The department has one laboratory equipped with advanced instruments different types of model, specimen, charts, permanent slides, microscopes and equipments. We are very proud to offer extra disciplinary courses and thereby enhancing the life skills of the students. </p>
								</div>	
						</div>			
					</div>
					<div className="row">
						<div className="col-12 col-xl-4 col-sm-12">
							<div className="vision-bg ml-null">
								<div className="hd"> 
									<h3><img src={visionImage} alt="vision" />Vision</h3>
								</div>
								<div className="cnt">
									<p>Department of zoology is to be the globally recognized premier academic department for teaching and research excellence through the emerging technologies in multidisciplinary courses of zoological field such as modern biotechnology, recent trends in microbiology, new methods for conserve of animal diversity and value added courses etc.</p>
								</div>
							</div>
						</div>
							<div className="col-12 col-xl-8 col-sm-12">							
							<div className="vision-bg ml-null">
								<div className="hd">
									<h3><img src={missionImage} alt="mission"  />Mission</h3>
								</div>
								<div className="cnt">
									<ul>
                                        <li>To provide leadership in science and technology.</li>
                                        <li>To create awareness of the need to explore understand and conserve animal diversity.</li>
                                        <li>To train students in practical knowledge and make them to apply in any activity requiring zoological field.</li>
                                        <li>To develop our students self employable in various fields of zoology such as sericulture, vermiculture, aquaculture, dairy farm, apiculture and poultry science etc.,</li>
                                        <li>To involve the student in outreach and extension activity.</li>
                                    </ul>
								</div>
							</div>
						</div>								
					</div>
				</div>
		</section>
        
        <section className="testimonial-box-bg custom-box-bg">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<h3 className="tt">Highlights:</h3>											
					</div>
				</div>
				<div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4>Hands on training programme on sericulture and silkworm rearing conducted for the rural women in an around the campus and their district.</h4>									
								</div>
								<div className="counter">
									<h4>Training session on vermicompost production in the vermiyard unit established in the campus.</h4>									
								</div>
                                <div className="counter">
									<h4>Students benefit through regular industrial and field visits to CMFRI (Central Marine Fisheries Research Institute) and Tamilnadu State Sericulture department and private farms for silkworm rearing and mulberry maintenance.</h4>									
								</div>
							</div>
							
						</div>												
					</div>
				</div>
                <div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">							
							<div className="counter-bg">								
								<div className="counter">
									<h4>Well maintained laboratory with diverse collection of specimens, skeletons, embryos and rare fossils.</h4>									
								</div>
                                <div className="counter">
									<h4>Paper presented in various national international conferences by the faculty members and students.</h4>									
								</div>
								<div className="counter">
									<h4>Students secure university gold medals and ranks in university end semester examinations.</h4>									
								</div>
							</div>							
							
						</div>												
					</div>
				</div>
                <div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4>To collaborate eco-friendly activity with green club to raise the herbal garden in the campus and to distribute the herbal nursery to students and peoples in and around the campus and rural areas.</h4>									
								</div>								
							</div>
						</div>												
					</div>
				</div>
			</div>
		</section>				
        <Footer></Footer>
        </>
    )
}