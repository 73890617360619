import React from "react";
import campusImage from '../assets/images/thiravium-group-campus.jpg';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import computerLabImage from '../assets/images/computer-lab.jpg';
import laboratoryImage from '../assets/images/Laboratory.jpg';
import libraryImage from '../assets/images/Library.jpg';
import transportImage from '../assets/images/Transport.jpg';
import chairmanImage from '../assets/images/chairman.jpg';
import Header from './Header';
import Footer from './Footer';

export default function HomeScreen(){

	
    return(
        <>
			<Header></Header>			
            <section className="hero-banner-slider"  style={{backgroundImage: `url(${introBannerImage})`}}  >
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8">
						<div className="hero-banner-intro">
							<h1 className="tt">The goal of true education is a holistic development.</h1>							
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="hero-banner-content">					
					<p className="sb-tt">Thiravium Group of Institutions ensures imparting Values, Ethics and Confidence to young women to help harness their full potential, thereby creating societal capital necessary for establishing a healthy society.</p>
				</div>
			</div>
		</section>
		
		<section className="hero-about-us">
			<div className="container-fluid">				
				<div className="row">
					<div className="col-12 col-xl-5">
						<div className="about-us-img">														
							<img src={campusImage} alt="campus" />					
						</div>
					</div>
					<div className="col-12 col-xl-7">
						<div className="cnt">
						    <h2 className="tt"><b>Welcome to</b> Thiravium Group of Institutions</h2>
							<p className="sb-tt">In this great institute, our students are surrounded by astonishing resources – a learned and caring faculty, a well – stocked library, superb sport facilities and extra – curricular opportunities covering every conceivable interact from entrepreneurship to community service. They have complete freedom to explore and to learn about new subjects.</p>		
									
						</div>
					</div>
				</div>
			</div>
		</section>
		
		
		<section className="feature-bg">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-12">
						<div className="feature-box-content">
							<h4 className="tt"><b>Reason to choose</b> Why Thiravium Institutions?</h4>
						</div>
					</div>
				</div>
				
				<div className="row">
					<div className="col-12 col-xl-4">
						<div className="feature-box-list">
							<div className="list">
								<div className="lft">
									<img src={computerLabImage} className="img" alt="computer lab"/>
								</div>
								<div className="rgt">
									<h5>WIFI Enabled Computer Lab</h5>
									<p>The computer lab provides space for computer services. The computers in every workstation</p>							
								</div>
							</div>
							<div className="list">
								<div className="lft">
									<img src={transportImage} className="img" alt="transport"/>
								</div>
								<div className="rgt">
									<h5>Transport Facilty</h5>
									<p>Transport facilities are available for students. All safety measures have been formulated and implemented.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-xl-4">
						<div className="feature-box-list">
							<div className="list">
								<div className="lft">
									<img src={laboratoryImage} className="img" alt="laboratory"/>
								</div>
								<div className="rgt">
									<h5>Fully Equipped Laboratory</h5>
									<p>The Laboratory is well equipped to conduct experiments as per the syllabus of the university</p>
								</div>
							</div>
							<div className="list">
								<div className="lft">
									<img src={libraryImage} className="img" alt="library"/>
								</div>
								<div className="rgt">
									<h5>WIFI Enabled Computer Lab</h5>
									<p>The computer lab provides space for computer services. The computers in every workstation</p>							
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-xl-4">
						<div className="feature-box-list">
							<div className="list">
								<div className="lft">
									<img src={libraryImage}  className="img" alt="library"/>
								</div>
								<div className="rgt">
									<h5>The big Library</h5>
									<p>There is a large library with a vast collection of books. Labelled racks are provided for easy identification.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className="testimonial-box-bg">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-7">
						<h3 className="tt">Chairman’s Message</h3>
						<div className="testimonial-box">
							<div className="list">
								<div className="lft">
									<img src={chairmanImage} className="img" alt="chairman"/>
								</div>
								<div className="rgt">
									<h5>Dr. T. Pandiyaraj MBBS., DMRD. (Chairman)</h5>
									<p>It is a undeniably a great pleasure to address you through our 'College Website'. I have the privilege of offering a lending hand to the learning, development and care of such wonderfully talented young students. Our approach focuses on institutionalizing an efficient, integrated and interdisciplinary approach to ensure competitiveness and versatility in order to benefit from the emerging global trends.</p>
								</div>
							</div>
						</div>
						
					</div>
					<div className="col-12 col-xl-5">
						<div className="hero-counter-bg">
							<div className="counter-bg">
								<div className="counter">
									<h4>40</h4>
									<p>years of Academic Excellence</p>
								</div>
								<div className="counter">
									<h4>1000+</h4>
									<p>years of Academic Excellence</p>
								</div>
							</div>
						</div>
						<div className="hero-counter-bg">
							<div className="counter-bg">
								<div className="counter bg-dark-light">
									<h4>40</h4>
									<p>years of Academic Excellence</p>
								</div>
								<div className="counter bg-transparent">
									<h4>1000+</h4>
									<p>years of Academic Excellence</p>
								</div>
							</div>
						</div>
					</div>
				</div>	
			</div>
		</section>
		<Footer></Footer>
        </>
    )
}