import React from "react";
import { Link } from 'react-router-dom';
import whatappImage from '../assets/images/whatsapp-icon.png';
export default function Footer(){
    return(
        <>
         <footer className="footer-bg">
		 	<a href="https://wa.me/7373710622?text=Hello" rel="noreferrer" target="_blank" className="whatspp-icon">
				<img src={whatappImage}  alt="whatspp"/> Contact us on WhatsApp</a>

			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-4">
						<div className="address-bg">
							<h4>Contact Address</h4>
							<p>Thiravium Group of Institutions <br />
							E-mail : <a href="mailto:ceo@thiraviumcolleges.org" rel="noreferrer" target="_blank">ceo@thiraviumcolleges.org</a><br />
							Cell: 7373710622, 32, 21, 27<br />
							Mount Jerusalem<br />
							Periyakulam Road, Kailasapatty<br />
							Thamarikulam Po, Theni Dt - 625 605. </p>
						</div>
					</div>
					<div className="col-12 col-xl-4">
						<div className="footer-list">
							<h4>Our Institutions</h4>							
							<ul>
								<li>
								<Link to="/arts-and-science">College of Arts &amp; Science</Link>								
								</li>
								<li>
								<Link to="/college-of-education">College of Education</Link>
								</li>
								<li>
								<Link to="/college-of-nursing">College of Nursing</Link>
								</li>
								<li>
								<Link to="/paramedical">Paramedical College</Link>
								</li>
								<li>
								<Link to="/physiotherapy">College of Physiotherapy</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-xl-4">
					<div className="footer-list">
							<h4>Quick Links</h4>
							<ul>
								<li>
									<Link to='/online-application'>Online Application</Link>
								</li>
								<li>
									Facilities
								</li>
								<li>
									<Link to='/gallery'>Gallery</Link>
								</li>
								<li>
									Student Feedback
								</li>
								<li>
									Caste Injustice Complaints
								</li>
								<li>
									<Link to='/contact-us'>Contact Us</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>	
				<div className="row">
					<div className="col-12">
						<p>© Thiravium Group of Institution. All Rights Reserved | Powered by CS &amp; CA Dept </p>
					</div>
				</div>
			</div>
		</footer>
        </>
    )
}