import React, { useState } from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import artPrincipalImage from '../assets/images/arts_principal.jpeg';
import missionImage from '../assets/images/mission-icon.png';
import visionImage from '../assets/images/vision-icon.png';
import { Link } from 'react-router-dom';

export default function ArtsScienceScreen(){
	const[showTab,setShowTab] = useState(true);
    return(
        <>
          <Header></Header>
          <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Arts and Science College</h1>		
							<p className="quotes-tt">"Education is a shared commitment between dedicated teachers, motivated students and enthusiastic parents with high expectations"
							<br/><b>-Bob Beauprez</b></p>
							
						</div>
					</div>
				</div>
			</div>
			
		</section>
		<section className="testimonial-box-bg">
			<div className="container-fluid">
				<div className="row custom-nav-pills">
					<div className="col-12 col-xl-4 col-sm-6 ">
						<nav className="nav nav-pills flex-column flex-sm-row">
						  <div className={showTab ? 'flex-sm-fill text-sm-center nav-link active' : 'flex-sm-fill text-sm-center nav-link'} onClick={()=>{return setShowTab(true)}}>UG Departments</div>
						  <div className={!showTab ? 'flex-sm-fill text-sm-center nav-link active' : 'flex-sm-fill text-sm-center nav-link'} onClick={()=>{return setShowTab(false)}}>PG Departments</div>
						</nav>
					</div>
				</div>
				{showTab && 
				<div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4><Link to="/ba-tamil">B.A(Tamil)</Link></h4>									
								</div>
								<div className="counter">
									<h4><Link to="/ba-english">B.A(English)</Link></h4>								
								</div>
								<div className="counter">
									<h4><Link to="/ba-history">B.A(History)</Link></h4>								
								</div>
								<div className="counter">
									<h4><Link to="/bsc-maths">B.Sc(Maths)</Link></h4>								
								</div>
																
							</div>
						</div>
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">			
								<div className="counter bg-dark-light">
									<h4><Link to="/bsc-physics">B.Sc(Physics)</Link></h4>								
								</div>
								<div className="counter bg-transparent">
									<h4><Link to="/bsc-chemistry">B.Sc(Chemistry)</Link></h4>									
								</div>
								<div className="counter bg-dark-light">
									<h4><Link to="/bsc-zoology">B.Sc(Zoology)</Link></h4>								
								</div>
								<div className="counter bg-transparent">
									<h4><Link to="/bsc-computer-science">B.Sc(Computer Science)</Link></h4>									
								</div>
								
								
							</div>
						</div>
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
							<div className="counter bg-transparent">
									<h4><Link to="/bca">B.C.A</Link></h4>									
								</div>
								<div className="counter bg-dark-light">
									<h4><Link to="/bcom-regular">B.Com(Regular)</Link></h4>								
								</div>
								<div className="counter bg-transparent">
									<h4><Link to="/bcom-regular">B.Com(CA)</Link></h4>								
								</div>			
								<div className="counter bg-dark-light">
									<h4></h4>								
								</div>						
							</div>
						</div>
					</div>
				</div>	
				}
				{!showTab && 
				<div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4><Link to="/ma-english">M.A(English)</Link></h4>									
								</div>
								<div className="counter">
									<h4><Link to="/mcom-regular">M.Com(Regular)</Link></h4>								
								</div>
								<div className="counter">
									<h4><Link to="/msc-computer-science">M.Sc(Computer Science)</Link></h4>								
								</div>						
							</div>
						</div>
					</div>
				</div>	
				}
			</div>
		</section>	
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="cnt spl-cnt">
						    
							<div className="feature-box-list sql-ft  hgt-box">
									<div className="list">
										<div className="lft">
											<img src={artPrincipalImage} className="img" alt="arts principal" />
										</div>
										<div className="rgt">
											<h5>Dr.S.PATTAMMAL M.A.M.A.M.Ed.M.Phil.P. hd.</h5>
											<p>(Principal)</p>							
										</div>
									</div>
								</div>
								<p className="tt "><b className="w-auto">Principal's Message,</b></p>
							<p className="sb-tt">Thiravium College of Arts and Science College for Women has been started with a vision of educating the down trodden young women of Periyakulam and theni district. This noble vision stems from the philanthropic minds of our chairman Dr.Pandiaraj M.B.B.S., DMRD. and Mrs. Hemalatha pandiaraj M.B.B.S. The ultimate goal of our college education is to empower young minds of women through quality and innovative pedagogical and process and practical experience towards the recent development in the field of study. We aim to tap up the faculties of young women and mould them to face the competitive and ‘survival of the fittest’ 
							world with confidence and determination along with a health body </p>

<p className="sb-tt">It is with great honour and pride, I address you through our College Website. I feel humbled to be at the helm of affairs of Thiravium College of Arts & Science for Women, an Institution which is doing yeoman service by offering quality education for the women folk. Our institution has carved a niche for itself in the field of Education in the grandeur of Thiravium College of Arts and Science for Women.</p>

<p className="sb-tt">Our site highlights the achievements and activities of our students in different spheres. Infused with a passion to excel, motivated to think and dream of achieving the execellence, our campus is bustling with energy and excitement of a new year.</p>

<p className="sb-tt">We are not contented to rest on our laurels, we rather consistently strive hard to make our College a centre of Excellence regionally, nationally and globally. I take this occasion to render my accolade for the progress of our institution by congratulating our crew for their untiring endeavors.</p>	
<p className=" sb-tt spl-cnt">"Education is the most powerful weapon which We can use to change the world<br/>
--Nelson Mandela"</p>

</div>
	
						</div>
						<div className="col-12 col-xl-4 col-sm-12">
							<div className="vision-bg">
								<div className="hd">
									<h3><img src={missionImage} alt="mission"/>Mission</h3>
								</div>
								<div className="cnt">
									<p>We provide a place for students and faculties to learn and appreciate the power of information through various print and electronic materials.Our students will be equipped with world className research reference environment.</p>
									<p>We create platform for the teachers to utilize the resources for quality course content and enrich their tasks.</p>
								</div>
							</div>
							<div className="vision-bg">
								<div className="hd">
									<h3><img src={visionImage} alt="vision"/>Vision</h3>
								</div>
								<div className="cnt">
									<p>We develop organize and provide access to and preserve materials to meet the needs of present and future generations of students and faculties. We also explore and implement various technologies and services to deliver information.</p>
								</div>
							</div>
						</div>
						
					</div>
					
					
				</div>
			
		</section>
		
          <Footer></Footer>
        </>
    )
}