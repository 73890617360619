import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import missionImage from '../assets/images/mission-icon.png';
import visionImage from '../assets/images/vision-icon.png';

export default function BscChemistryScreen(){
    return(
        <>
        <Header></Header>
        <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Department of Chemistry</h1>	
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				    <div className="row">					
						<div className="col-12 col-xl-12 col-sm-12">
								<div className="cnt spl-cnt">						    
									<p>The department of Chemistry has been functioning since 2013, with offer under graduate programs and certificate courses. The Department has well equipped lab facilities. We are very proud to offer consumer product training courses and thereby enhancing the life skill of the students.</p>
								</div>	
						</div>			
					</div>
					<div className="row">
						<div className="col-12 col-xl-4 col-sm-12">
							<div className="vision-bg ml-null">
								<div className="hd"> 
									<h3><img src={visionImage} alt="vision" />Vision</h3>
								</div>
								<div className="cnt">
									<p>The Department of chemistry strives for excellence in all aspects of teaching and research to enable engineering students reach high qualities required in industries, academic, research organisations and serve the society at national and standards.</p>
								</div>
							</div>
						</div>
							<div className="col-12 col-xl-8 col-sm-12">							
							<div className="vision-bg ml-null">
								<div className="hd">
									<h3><img src={missionImage} alt="mission"  />Mission</h3>
								</div>
								<div className="cnt">
									<p>Our mission is to maintain a department that is regarded as equal to any in terms of its relevance of teaching and coaching of chemcotex in Value Added Course, its quality of support and facilities and the learning opportunities and working reframe it efforts. To awaken the young minds and discover their talents both in theory and in practical chemistry, through dedication of teaching, commitment to students and innovative instructional methods. To support the developmental activities of the college and make the department vibrant.</p>
								</div>
							</div>
						</div>								
					</div>
                    <div className="row">					
						<div className="col-12 col-xl-12 col-sm-12">
								<div className="cnt spl-cnt">	
                                    <h4>Department highlights</h4>					    
									<p>Visiting Companies on - Campus/ off - Campus interact with the eligible student directly during pre-placement talks. During these sessions, they provide overviews of the job profiles, job description, Compensation, Company’s expectations and other specific requirements.</p>
                                    <p>Our department student M. Muthumeena (2017-2020) Secured second rank in University End Semester Examination.</p>
                                </div>	
						</div>			
					</div>
				</div>
			
		</section>
        <Footer></Footer>
        </>
    )
}