import React, { useState } from "react";
import headerLogo from '../assets/images/thiravium-logo.png';
import { Link } from 'react-router-dom';
import IonIcon from '@reacticons/ionicons';


export default function Header(){

	const [showMenu,setShowMenu] = useState(false);
 
	
    return(    
    <header className="header-bg">				
			<nav className="navbar navbar-expand-lg navbar-light bg-transparent logo-bg">
				<Link to='/'>			  
					<img src={headerLogo} alt="thiravium group of institutions"/>
				</Link>		
				<button className="btn btn-default hamburger" onClick={()=>{return setShowMenu(!showMenu)}}>
						<IonIcon name={!showMenu ? 'ellipsis-vertical-outline' :'close-outline'}  />
						
				</button>	  			  					  
			 </nav>
			 
			 <div className={showMenu ? 'd-block cm-navbar-menu-list' : 'd-sm-block cm-navbar-menu-list mn-ds-none' }>
				  <ul>
					<li>
						<Link to='/about-us'>About us</Link>
					</li>
					<li>
						<span>Management</span>
						<ul className="sub-menu"> 
							<li>
								<Link to="/chairman">Chairman</Link>
							</li>
							<li>
								<Link to="/secretary">Secretary</Link>
							</li>
							<li>
								<Link to="/directors">Directors</Link>
							</li>
							<li>
								<Link to="/coo">COO</Link>
							</li>
						</ul>
					</li>
					<li>
						<span>Our Institutions</span>
						<ul className="sub-menu"> 
							<li>
								<Link to="/arts-and-science">Arts & Science College</Link>
							</li>
							<li>
								<Link to="/college-of-education">College of Education</Link>
							</li>
							<li>
								<Link to="/college-of-nursing">College of Nursing</Link>
							</li>
							<li>
								<Link to="/paramedical">Paramedical College</Link>
							</li>
							<li>
								<Link to="/physiotherapy">College of Physiotherapy</Link>
							</li>
						</ul>
					</li>
					<li>
						<span>Courses</span>
						<ul className="sub-menu"> 
							<li>
								<Link to="/b-ed">B.Ed</Link>
							</li>
							<li>
								<Link to="/b-sc-nursing">B.Sc(Nursing) 4 Years</Link>
							</li>
							<li>
								<Link to="/b-sc-radiology">B.Sc(Radiography and Imaging Technology)</Link>
							</li>
							<li>
								<Link to="/">UG Courses</Link>
								<ul className="sub-more-menu"> 
									<li><Link to="/ba-tamil">B.A(Tamil)</Link></li>
									<li><Link to="/ba-english">B.A(English)</Link></li>
									<li><Link to="/ba-history">B.A(History)</Link></li>
									<li><Link to="/bsc-maths">B.Sc(Maths)</Link></li>
									<li><Link to="/bsc-physics">B.Sc(Physics)</Link></li>
									<li><Link to="/bsc-chemistry">B.Sc(Chemistry)</Link></li>
									<li><Link to="/bsc-zoology">B.Sc(Zoology)</Link></li>
									<li><Link to="/bsc-computer-science">B.Sc(Computer Science)</Link></li>
									<li><Link to="/bca">B.C.A</Link></li>
									<li><Link to="/bcom-regular">B.Com(Regular)</Link></li>
									<li><Link to="/bcom-regular">B.Com(CA)</Link></li>
								</ul>
							</li>
							<li>
								<Link to="/">PG Courses</Link>
								<ul className="sub-more-menu"> 
									<li><Link to="/ma-english">M.A(English)</Link></li>
									<li><Link to="/mcom-regular">M.Com(Regular)</Link></li>
									<li><Link to="/msc-computer-science">M.Sc(Computer Science)</Link></li>
								</ul>
							</li>
						</ul>
					</li>
					<li>
						<Link to='/gallery'>Gallery</Link>
					</li>
					<li>
						<Link to='/online-application'>Online Application</Link>
					</li>
					<li>
						<Link to='/contact-us'>Contact Us</Link>
					</li>
					
				  </ul>
			  </div>
			 
		</header>
		
    )  
    
    }