import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import missionImage from '../assets/images/mission-icon.png';
import visionImage from '../assets/images/vision-icon.png';

export default function BscMathsScreen(){
    return(
        <>
        <Header></Header>
        <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Department of Maths</h1>		
							
							
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
						<div className="col-12 col-xl-12 col-sm-12">
								<div className="cnt spl-cnt">						    
									<p>The maths department our college is functioning from 2013. The improvement and achievement in various field are the gift of mathematics. The world of education without mathematics would be non-existent. The demand for the students of mathematics is bound be consistent and constant. At present the department of mathematics having five qualified staff members and are providing the basic maths knowledge to our student.</p>
								</div>	
						</div>			
					</div>
					<div className="row">
						<div className="col-12 col-xl-4 col-sm-12">
							<div className="vision-bg ml-null">
								<div className="hd"> 
									<h3><img src={visionImage} alt="vision" />Vision</h3>
								</div>
								<div className="cnt">
									<p>To develop our intellectually rigorous community of students and faculty together engaging in teaching and learning that advance knowledge in diverse areas of mathematics.</p>
								</div>
							</div>
						</div>
							<div className="col-12 col-xl-8 col-sm-12">							
							<div className="vision-bg ml-null">
								<div className="hd">
									<h3><img src={missionImage}  alt="mission" />Mission</h3>
								</div>
								<div className="cnt">
									<ul>                                        
                                    <li>To provide professional services based on our diverse mathematical and statistical expertise to the scientific, technical and educational community.</li>
                                    <li>To achieve high standards of excellence in generating and a propagating knowledge in mathematics department is committed to a providing an education that combines rigorous academics with joy of discovery.</li>
                                    <li>To provide an environment where students can learn become competent user of mathematics and understand the use of mathematics in other disciplines.</li>
                                    </ul>
								</div>
							</div>
						</div>								
					</div>
				</div>
			
		</section>
		<section className="testimonial-box-bg custom-box-bg">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<h3 className="tt">The values that guide our decisions, strategies and actions are:</h3>											
					</div>
				</div>
				<div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4>Excellence</h4>									
								</div>
								<div className="counter">
									<h4>Integrity</h4>									
								</div>
                                <div className="counter">
									<h4>Leadership</h4>									
								</div>
                                <div className="counter">
									<h4>Innovation and</h4>									
								</div>
                                <div className="counter">
									<h4>Ethics</h4>									
								</div>
							</div>
						</div>												
					</div>
				</div>	
				
			</div>
		</section>
        <Footer></Footer>
        </>
    )
}