import React from "react";
import Header from './Header';
import Footer from './Footer';

export default function DirectorsScreen(){
    return(

        <>
        <Header></Header>
        <Footer></Footer>
        </>
    )
}