import React from "react";
import Header from './Header';
import Footer from './Footer';
import chairmanImage from '../assets/images/chairman.jpg';
import campusImage from '../assets/images/thiravium-group-campus.jpg';

export default function ChairmanScreen(){
    return(

        <>
        <Header></Header>
        <section className="hero-banner-slider sql-ft"  style={{backgroundImage: `url(${campusImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-10">
							<div className="feature-box-list sql-ft ad-spl-ft hgt-box">
									<div className="list">
										<div className="lft">
											<img src={chairmanImage} className="img" alt="chairman" />
										</div>
										<div className="rgt">
											<span>Chairman's Desk</span>
											<h5>"You don't have to be great to start, but you have to start to be great."</h5>
											<h6>Dr. T. Pandiyaraj MBBS., DMRD.</h6>
											<p>Chairman</p>							
										</div>
									</div>
								</div>
						
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
					<div className="col-12 col-xl-7">
						<div className="cnt spl-cnt">
						    
							
								<p className="tt "><b className="w-auto">Dear Students,</b></p>
							<p className="sb-tt">Our aim is to teach students to LEARN, not just STUDY. Hence, we strive to travel beyond the boundaries of mere books. We've discovered that whilst the future is abstract and unknown, the youth we hold in our hands is tangible and moldable.</p>

<p className="sb-tt">Dear students, "You are the nation’s architects. You are the movers of technology. You are the agents of change." It is our fervent hope that the years that you spend in Thiravium group of Institutions would enable you to equip yourself with leadership and managerial skills. Your greatest contribution to your parents, community, and nation will be the knowledge you will receive, the fine traits you will internalise, and the technical skills you will learn to apply.</p>

<p className="sb-tt">Interface by allowing for educational exchange on an international scale. Our aim is to establish benchmarks in academic excellence and holistic grooming to prepare our students to overcome the career challenges in today's increasingly globalized economic conditions. As a result, Thiravium Group of Institutions has acquired the respect of both the students and society at large.</p>

<p className="sb-tt">I am delighted to welcome you all to the Thiravium family. I pray to the Almighty to bless you with a great future.</p>	


</div>
	
						</div>
						<div className="col-12 col-xl-5">
							<div className="quotes-bg">
								We cannot always build the future for our youth, but we can build our youth for the future.
								<b>Franklin D. Roosevelt</b>
							</div>
						</div>
						
					</div>
					
					
				</div>
			
		</section>
        <Footer></Footer>
        </>
    )
}