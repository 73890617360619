import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import missionImage from '../assets/images/mission-icon.png';
import visionImage from '../assets/images/vision-icon.png';

export default function BAHistoryScreen(){
    return(
        <>
        <Header></Header>
        <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Department of History</h1>		
							
							
						</div>
					</div>
				</div>
			</div>			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
						<div className="col-12 col-xl-12 col-sm-12">
								<div className="cnt spl-cnt">						    
									<p>The Department of History was established in 2016 and department intake was 60. Many of the students of the department come from small towns and mofussil areas, some belong to the under privileged sections of Rural Society. The faculty Strives to improve their knowledge of the past and their ability at analytical thinking coherent argument and clarity of Expression.</p>
								</div>	
						</div>			
					</div>
					<div className="row">
						<div className="col-12 col-xl-6 col-sm-12">
							<div className="vision-bg ml-null">
								<div className="hd">
									<h3><img src={missionImage}  alt="mission"/>Mission</h3>
								</div>
								<div className="cnt">
									<ul>
									<li>To make students highness in constitution and society in any situation.</li>
									<li>To train the students into a good citizens.</li>
									<li>To promote patriotism and universal Brotherhood.</li>
									<li>To promote them Self Confidence.</li>
									<li>To promote students need to communal and Regional Harmony.</li>
									<li>To provide students in various disciplinary courses such as History Society and Culture of India and World History.</li>
									</ul>
								</div>
							</div>
						</div>
							<div className="col-12 col-xl-6 col-sm-12">							
							<div className="vision-bg ml-null">
								<div className="hd">
									<h3><img src={visionImage} alt="vision" />Vision</h3>
								</div>
								<div className="cnt">
									<ul>
										<li>To make students to become good Academicians, Parliamentarians, Entrepreneurs, Administrators, Lawyers, Writers, Politicians, Journalists and Leaders etc.</li>
										<li>To awaken awareness on cultural heritage maintains Archaeological monuments and Environment.</li>
										<li>To train the students on social, economic and political trends are happening at the National and International level.</li>

									</ul>
								</div>
							</div>
						</div>								
					</div>
				</div>			
		</section>
		<section className="testimonial-box-bg custom-box-bg">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<h3 className="tt">Highlights of our department</h3>											
					</div>
				</div>
				<div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4>Educational Tour to historical places</h4>									
								</div>
														
							</div>
						</div>											
					</div>
				</div>	
				<div className="row">
					<div className="col-12">
						<h3 className="tt">Academic Achievements:</h3>											
					</div>
				</div>
				<div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4>University Rank Holder – II Rank </h4>		
									<p>C.Banupriya 2018 – 2020</p>
								</div>
								<div className="counter">
									<h4>Awarded Rani Mangammal Endowment Scholarship for University meritorious students from Mother Teresa Women’s University</h4>		
									<p>K.Sarojini - April 2018 I Semester I Rank <br/> V.Janani - November 2020 III Semester I Rank</p>
								</div>
								<div className="counter">
									<h4>Our Department Staff member have been awarded Best Proficiency for Institute by the Mother Teresa Women’s University for the year 2017 in recognition of our academic performance.</h4>
									
								</div>								
							</div>
						</div>											
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h3 className="tt">Sports Achievements:</h3>											
					</div>
				</div>	
				<div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4>Winner in Inter Collegiate Basket Ball Tournament at Mother Teresa Women’s University 2018 Kodaikanal. </h4>		
									<p>Renjitha. A</p>
								</div>
								<div className="counter">
									<h4>Winner in Inter University Basket Ball Tournament at SRM Institute of Science & Technology 2018 Kattakulathur. </h4>		
									<p>Renjitha. A</p>
								</div>
								<div className="counter">
									<h4>Winner in Inter Collegiate Kho - Kho Tournament 2021 at Thiravium College of Arts and Science for Women, Kailasapatti. </h4>
									<p>
										Deepika. K <br/>
										Insiya. S <br/>
										Sumithra. V <br/>
										Shalini Priya. P <br/>
									</p>
								</div>								
							</div>							
						</div>											
					</div>
				</div>	
				<div className="row">
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4>Winner in Inter Collegiate Basket Ball Tournament at Mother Teresa Women’s University 2021 Kodaikanal.  </h4>		
									<p>									
										Deepika. K<br/>
										Insiya. S<br/>
										Sumithra. V<br/>
										Shalini Priya. P<br/>
										Abirami.R<br/>
										Aruna Devi. M
									</p>
								</div>
								<div className="counter">
									<h4>Winner in Inter Collegiate Athletic Tournament at Nadar Saraswathi College of Arts and Science for Women 2021 Vadaputhupatti  </h4>		
									<p>
										800 Mtrs: Insiya. S <br/>
										400 Mtrs: Deepika. K<br/>
										Relay: Deepika. K
									</p>
								</div>
								<div className="counter">
									
								</div>								
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</section>
        <Footer></Footer>
        </>
    )
}