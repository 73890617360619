import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import principalImage from '../assets/images/chairman.jpg';
import missionImage from '../assets/images/mission-icon.png';
import visionImage from '../assets/images/vision-icon.png';
import clinicImage from '../assets/images/clinic.jpg';
import gymImage from '../assets/images/gym.jpg';
import testImage from '../assets/images/test.jpg';
import libraryImage from '../assets/images/special-library.jpg'

export default function ParamedicalScreen(){
    return(
        <>
          <Header></Header>
          <section className="hero-banner-slider"  style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Paramedical College</h1>		
							<p className="quotes-tt">"An investment in knowledge pays the best dividends"
							<b>-Benjamin Franklin</b></p>
							
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="cnt spl-cnt">
						    
							<div className="feature-box-list sql-ft  hgt-box">
									<div className="list">
										<div className="lft">
											<img src={principalImage} className="img" alt="principal"/>
										</div>
										<div className="rgt">
											<h5>Dr. T. Pandiyaraj MBBS., DMRD.</h5>
											<p>(Principal)</p>							
										</div>
									</div>
								</div>
								<p className="tt "><b className="w-auto">Principal’s Message,</b></p>
								<p className="sb-tt">I take it as my privilege and honour to furnish this message mentioned in the quote as Principal of this institution for all students and their parents as well. With the changing global scenario and rapid technological development, new innovative professions are emerging rapidly. Thiravium Paramedical College was established to provide modern education. This institute was developed in 2014 and aimed to match its performance standards with the premier professional institutes in our country.</p>

<p className="sb-tt">The best facilities and environment are provided to the students for their overall development. Our dream is to give the students not only quality education but also provide them employment opportunities in their respective professions through placements. Although paramedical courses are highly specialized, it remains fundamentally a caring profession. The single important mission of a good and reputed institution is to equip young men and women with knowledge, skills and personal attribute conducive to the needs of society.</p>

<p className="sb-tt">Our Institute is committed to excellence in Paramedical education and medical services and aims to prepare paramedical staff to be effective technicians and leaders who will work to improve the health and healthcare of people. Thiravium Paramedical College has got an excellent infrastructure, beautiful campus, supportive learning environment career –oriented discipline, good clinical features such as – MRI scan, CT Scan, Ultrasonagraphy(USG), Intravenuspyelogram(IVP), Hysterosalphingogram(HSG), Barium Swallow, Doppler scan, FNAC, Electrocardiogram(ECG), Electroencephalogram(EEG) and library facilities attract the students to study here. We are also focusing to start Lab technician course in future, as there is a great demand for trained graduate in the this area.We also provide highest and Atomic Energy</p>

<p className="sb-tt">Regulatory Board (AERB) accredited educational process through formal didactic and state-of the-art clinical experiences that will render qualified, patient focused, compassionate, critical thinker radiographers technologist for the community who are engaged in lifelong learning.</p>

<p className="sb-tt">The faculties are efficient and highly motivated who plan meticulously academic as well as co-curricular activities for the students with the aims to train dedicated and expert radiographers, capable of contributing towards the development of emerging health care systems in public and private health care organizations, as well as community services.</p>

<p className="sb-tt">I welcome all the new students and wish them success throughout their academic journey in our college as successful Paramedical professional.</p>
								</div>
	
						</div>
						<div className="col-12 col-xl-4 col-sm-12">
							<div className="vision-bg">
								<div className="hd">
									<h3><img src={missionImage} alt="mission"/>Mission</h3>
								</div>
								<div className="cnt">
									
									<p className="sb-tt">
										Our mission is to provide quality patient care based on our strong commitment to provide education, research, innovation and collaboration.
									</p>
								</div>
							</div>	
							<div className="vision-bg">
								<div className="hd">
									<h3><img src={visionImage} alt="vision"/>Vision</h3>
								</div>
								<div className="cnt">
									<p>Our Vision is to develop leaders in nursing and health care whose actions, innovations and voices strengthen and transform the health of individuals and the community at large.</p>
								</div>
							</div>
						</div>
						
					</div>
					
					
				</div>
			
		</section>
		
		<section className="hero-about-us spl-hgt">
			<div className="container-fluid">
				
				<div className="row">	
					<div className="col-xl-4">
						<img src={clinicImage} className="img-tg" alt="clinic" />
					</div>	
					
					<div className="col-12 col-xl-8">
						<div className="cnt">
						    <h2 className="tt">Radiography and Imaging Technology</h2>
							<p className="sb-tt">
							Early diagnosis and prompt treatment is a method that should be followed in medical department for reducing mortality in our country. This motivating has enabled our Chairman has start Radiography and Imaging Technology course in Theni district in the year of 2014. Our organization was the first and the only one to introduce new medical courses in Theni district for women. It has been functioning successfully with proper academic and practical application.</p>
							
							<p className="sb-tt">Our students are encouraged to adopt newer technologies for their future academic activities.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		
		<section className="hero-about-us spl-hgt ad-spl-ht">
			<div className="container-fluid">				
				<div className="row">						
					<div className="col-12 col-xl-6">
						<div className="cnt">
							 <h2 className="tt">Physical Facilities</h2>
							<img src={gymImage} className="img-tg" alt="gym" />						   
							<p className="sb-tt">
							Our college has excellent infrastructure with spacious classNamerooms comprising of adequate seating arrangements, ventilation, lighting facilities and physical environment.Preclinical laboratories for:</p>
							<ul className="sb-tt">
								<li>Anatomy</li>
								<li>Whole Body MRI Scan (1.5 TESLA)</li>
								<li>Advanced Digital X-Ray</li>
								<li>Whole Body Spiral CT Scan</li>
								<li>Hi-Tech Computerized Lab</li>
								<li>5D Color Doppler</li>
								<li>ECG, EEG & OPG</li>
							</ul>
							<p>Well accommodated hostel with adequate rooms and mess. An Auditorium to celebrate special occasions like Christmas, college day etc.	</p>
						</div>
					</div>
					<div className="col-12 col-xl-3">
						<div className="cnt">
							 <h2 className="tt">College Library</h2>
							 <img src={libraryImage} className="img-tg" alt="library" />		
							  <p>In order to enhance and motivate student in the progressive process of learning, various efforts are being undertaken by the staff. These efforts include:</p>
							<ul className="sb-tt">																
								<li>Taking className using AV Aids like LCD, OHP, Models etc.</li>
								<li>Experts' Visits are planned and implemented</li>
							</ul>							
						</div>
					</div>
					<div className="col-12 col-xl-3">
						<div className="cnt">
							 <h2 className="tt">Clinical Experience</h2>
							<img src={testImage} className="img-tg" alt="test" />						   
							<p className="sb-tt">
							We select and provide rich clinical field experience which promotes Diagnosing skills, attitude and knowledge about client-care. Our Students gain their clinical experience at Vaigai Scan Theni. Which provide true knowledge and skills in assessting doctors with ultra sound and individual performing diagnostic test like X-Ray, CT, MRI Etc.</p>
						</div>
					</div>
					
				</div>
				<div className="row">
					<div className="col-12 col-xl-12">
						<div className="cnt">
							 <h2 className="tt">Conclusion</h2>												   
							<p className="sb-tt">
							Thiravium College of Radiography and imaging Technology which started in 2014 has crossed various milestones and has reached the present stage in providing preventive and curative services. This achievement was possible only with the support and cooperation of the management, all the faculties and students shouldered this responsibility since 2009 by preparing qualified nurses to assume responsibilities as professional and competent nurses and midwives to provide promotive, preventive, curative and rehabilitative services.</p>
						</div>
					</div>					
				</div>
			</div>
		</section>
          <Footer></Footer>
        </>
    )
}