import React from "react";
import Header from './Header';
import Footer from './Footer';
import chairmanImage from '../assets/images/chairman.jpg';
import secretaryImage from '../assets/images/secretary.jpg';
import campusImage from '../assets/images/thiravium-group-campus.jpg';

export default function AboutUsScreen(){
    return(
        <>
        <Header></Header>
        <section className="hero-banner-slider spl-cnt"  style={{backgroundImage: `url(${campusImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">About Us</h1>							
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
							
					</div>
				</div>
				<div className="row">					
					<div className="col-12">
						<div className="cnt spl-cnt">
						    <h2 className="tt"><b>Welcome to</b> Thiravium Group of Institutions</h2>
							<p className="sb-tt">By building people, we build society. By building leaders, we strengthen the nation’s Technology and Economy. Thiravium Group of Institutions has a firm belief that the goal of true education is a holistic development. Thiravium Group of Institutions ensures imparting Values, Ethics and Confidence to young women to help harness their full potential, thereby creating societal capital necessary for establishing a healthy society.</p>
							<p className="sb-tt">In this great institute, our students are surrounded by astonishing resources – a learned and caring faculty, a well – stocked library, superb sport facilities and extra – curricular opportunities covering every conceivable interact from entrepreneurship to community service. They have complete freedom to explore and to learn about new subjects.</p>		
								<p className="sb-tt">The management having a clear perception of the vision and mission, is totally committed to promote the socially backward women to utilize their innate potential, aiming at excellence in every sphere of life. It is our endeavor to ensure that every student’s needs and expectations are fulfilled thus enhancing their ability to acquire and apply knowledge. Our highly professional and dedicated faculty , drawn from academia and industry possesses impressive credentials a missionary zeal, in teaching and are involved in shaping careers in our Thiravium Group of Institutions.</p>

<p className="sb-tt">There can be no halfhearted measures when it comes to quality. We spare no effort in inculcating noble values into our students. Because, while it is our students who are shaped to make a difference, it is quality that gives them an edge a cutting edge.</p>
<div className=" sb-tt spl-cnt">
<p >I am sure that all colleges under Trinity Trust will grow exponentially in quality and size and will prove their worth by meeting the expectations of aspiring students and parents.</p>

<p >I welcome you to search for the never ending knowledge, venturing in untrodden path, seizing opportunities, never thought of as possible and be a beacon for generations to come.</p>

<p >I welcome you to the family of Thiravium...</p>

<p >I welcome you to the world of opportunities...</p>

<p >Wide open and inviting...</p>	
</div>
	
						</div>
					</div>
					
					
				</div>
			</div>
		</section>
		
		
		
		<section className="testimonial-box-bg">
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-8 col-12">
						<h3 className="tt text-center">Our Chairman and Secretary</h3>
						<div className="row">
							<div className="col-xl-6 col-12">					
								<div className="feature-box-list sql-ft">
									<div className="list">
										<div className="lft">
											<img src={chairmanImage} className="img" alt="chair man"/>
										</div>
										<div className="rgt">
											<h5>Dr. T. Pandiyaraj MBBS., DMRD.</h5>
											<p>Chairman</p>							
										</div>
									</div>
								</div>						
							</div>
							<div className="col-xl-6 col-12">						
								<div className="feature-box-list sql-ft">							
									<div className="list">
										<div className="lft">
											<img  src={secretaryImage} className="img" alt="secretary"/>
										</div>
										<div className="rgt">
											<h5>Dr. P. HEMALATHA MBBS.</h5>
											<p>Secretary</p>
										</div>
									</div>
								</div>						
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-12">
						<div className="hero-counter-bg">
							<div className="counter-bg spl-bg">
								<div className="counter">
									<h4>Vision</h4>
									<p>Our Mission is to spread the aura of knowledge by guiding students towards consistent progress for our country.</p>
								</div>
								<div className="counter">
									<h4>Mission</h4>
									<p>Our Vision is to empower the women folk intellectually to meet their Socio-economic constraints.</p>
								</div>
							</div>
						</div>
						
					</div>
				</div>
				
			</div>
		</section>
        <Footer></Footer>
        </>
    )
}