import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
//import principalImage from '../assets/images/edu_principal_subhasini.jpg';
import principalImage from '../assets/images/education_principal.jpg';

import visionImage from '../assets/images/vision-icon.png';
import campusImage from '../assets/images/thiravium-group-campus.jpg';

import libraryImage from '../assets/images/special-library.jpg';
import booksImage from '../assets/images/books.jpg';
import deskImage from '../assets/images/desk.jpg';

export default function CollegeEducationScreen(){
    return(
        <>
          <Header></Header>
          <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">THIRAVIUM COLLEGE OF EDUCATION-KAILASAPATTY</h1>		
							
							
						</div>
					</div>
				</div>
			</div>
			
		</section>
		{/* <section className="testimonial-box-bg">
			<div className="container-fluid">
				<div className="row custom-nav-pills">
					<div className="col-12 col-xl-4 col-sm-6 ">
						<nav className="nav nav-pills flex-column flex-sm-row">
						  <div className="flex-sm-fill text-sm-center nav-link active" aria-current="page" href="#">Downloads</div>
						  
						</nav>
					</div>
				</div>
				<div className="row">					
					<div className="col-12">
						<div className="hero-counter-bg spl-ft">
							<div className="counter-bg">
								<div className="counter">
									<h4><a href="https://docs.google.com/gview?url=https://thiraviumcolleges.org/docs/B.Ed/Form A.docx" rel="noreferrer" target="_blank">1.Form A</a></h4>									
								</div>
								<div className="counter">
									<h4><a href="https://docs.google.com/gview?url=https://thiraviumcolleges.org/docs/B.Ed/Form B.docx" rel="noreferrer" target="_blank">2.Form B</a></h4>									
								</div>
								<div className="counter">
									<h4><a href="https://docs.google.com/gview?url=https://thiraviumcolleges.org/docs/B.Ed/Form F-I Yr.docx" rel="noreferrer" target="_blank">3.Form F-I Yr</a></h4>									
								</div>
								<div className="counter bg-dark-light">
									<h4><a href="https://docs.google.com/gview?url=https://thiraviumcolleges.org/docs/B.Ed/Form F-II Yr.docx" rel="noreferrer" target="_blank">4.Form F-II Yr</a></h4>									
								</div>
								<div className="counter bg-transparent">
									<h4><a href="https://docs.google.com/gview?url=https://thiraviumcolleges.org/docs/B.Ed/Form G.docx" rel="noreferrer" target="_blank">5.Form G</a></h4>									
								</div>
								<div className="counter bg-dark-light">
									<h4><a href="https://docs.google.com/gview?url=https://thiraviumcolleges.org/docs/B.Ed/Form H.docx" rel="noreferrer" target="_blank">6.Form H</a></h4>									
								</div>
																
							</div>
						</div>
											
					</div>
				</div>	
			</div>
		</section>	 */}
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
					<div className="col-12 col-xl-12 col-sm-12">
						<div className="cnt spl-cnt">
						    
							<div className="feature-box-list sql-ft  hgt-box">
									<div className="list">
										<div className="lft">
											<img src={principalImage} className="img" alt="principal" />
										</div>
										<div className="rgt">
											<h5 className="hgt-adj">Dr.R.T. SAROJA M.Com.,M.Phil(com).,
												M.Ed., M.Phil(Edu)., Ph.D(com)., SLST(com)., PGDCA., Dip.in Yoga</h5>
											<p className="text-center">(Principal)</p>							
										</div>
									</div>
								</div>
								<p className="tt "><b className="w-auto">From the Principal’s Desk!,</b></p>
							<p className="sb-tt">Education is the powerful weapon to change the world. Teachers are the makers of History.  Teachers are the architects of the Society.  They are the builders of the Nation.  They can bring wonderful desirable behavior changes among the students through Education.  Moreover, Education is the most important tool to empower Women.  When a man is educated, his family alone developed.  But when a Woman is educated, their generations developed and enlighten and the entire Nation developed.  Thiravium Group of Institutions is established in Jeruselam Mount, Kailasapatty, Periyakulam, Theni (dt) with noble cause of serving the downtrodden Women folk in the remote area.  Thiravium College of Education provides education to the women students from underprivileged Society and light the lamp in their life by developing them into empowered student teachers.  The institution is having qualified and dedicated teaching fratemity who serve the Society with their tireless efforts.  All the Best to Teacher Educators and Student Teachers.</p>


							
							

</div>
	
						</div>
						
						
					</div>
					
					
				</div>
			
		</section>
		
		
          <Footer></Footer>
        </>
    )
}