import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';


export default function ContactUsScreen(){
    return(
        <>
        <Header></Header>
        <section className="hero-banner-slider"  style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Contact Us</h1>	
						</div>
					</div>
				</div>
			</div>
			
		</section>	
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
					<div className="col-12 col-xl-6 col-sm-12">
						<div className="cnt spl-cnt">
							<h4>Thiravium Group of Institutions</h4>
							<p className="spl-pl">Address</p>
							<p>Mount Jerusalem <br/>
							Periyakulam Road, Kailasapatty <br/>
							Thamarikulam Po., Theni Dt – 625605. </p>
							<p className="spl-pl"> E-mail : <a href="mailto:ceo@thiraviumcolleges.org">ceo@thiraviumcolleges.org</a>, <a href="mailto:coo.thiraviuminstitutions@gmail.com">coo.thiraviuminstitutions@gmail.com</a> <br/>
							Phone : 04546 - 269188, 04546 - 269088<br/>
							Mobile No. : 7373710622 </p>
							<h4>For Admission & Enquiry kindly reach us</h4>
							<p className="spl-tt"> Arts: 7373710632 <br/>
								B.Ed: 7373710621<br/>
								Nursing & Radiology: 7373710627 </p>
						</div>
	
						</div>
						<div className="col-12 col-xl-6 col-sm-12">
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2285.339910212222!2d77.53290357064608!3d10.092372923604822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b076a3f20dfa7ad%3A0x894f3c5320053bbf!2sThiravium+College+of+Education!5e1!3m2!1sen!2sin!4v1538602018316" height="450" style={{border: '1px solid rgba(0, 0, 0, 0.05)',width: '100%'}} allowfullscreen=""></iframe>
						</div>
						
					</div>
					
					
				</div>
			
		</section>
        <Footer></Footer>
        </>
    )
}