import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';


export default function OnlineApplicationScreen(){
    return(
        <>
        <Header></Header>
        <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-12 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Application for the academic year 2024-25</h1>		
							
							
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				<div className="row">					
					
					<div className="col-12 col-xs-5 col-sm-5">
						<div className="appln-bg">
							<h2>Online Application Available</h2>
							<div className="cnt">
								<h4>Thiravium College of Arts & Science for Women </h4>								
								<a className="btn btn-primary" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSd7Zc4W7S5WPY72J_tSl6NQPvkFLZIfIjuKvmnBwW63SuO35Q/viewform?usp=sf_link" rel="noreferrer">Under Graduate</a>
								<a className="btn btn-primary" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfEQeSF8cGMY4iGnKt0L67qPFp_-tuiZkTMnCCNthVbxdo-uQ/viewform?usp=sf_link" rel="noreferrer">Post Graduate</a>
							</div>
							<div className="cnt">
								<h4>Thiravium College of Education</h4>
								<a className="btn btn-primary" target="_blank" href="https://forms.gle/haVkvRr8RbPYuapS8" rel="noreferrer">Click Here</a>								
							</div>
							<div className="cnt">
								<h4>Thiravium College of Nursing </h4>
								<a className="btn btn-primary" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSecIpTkt_Pa7s0TIdXW-L1NEq1P36sqqwPla4jrAiGbT127Tw/viewform?usp=sf_link" rel="noreferrer">Click Here</a>
							</div>
							<div className="cnt">
								<h4>Thiravium Paramedical College</h4>
								<a className="btn btn-primary" target="_blank" href="https://forms.gle/5CSLvrQSEneT57Qt9" rel="noreferrer">Click Here</a>
							</div>
						</div>
					</div>
					<div className="col-12 col-xl-7 col-sm-7">
						<div className="cnt spl-cnt">							
							<p className="tt "><b className="w-auto">Instructions,</b></p>
							<p>Instructions to the Candidates. <br/> Please read the instructions carefully before filling the application form</p>
							<ul>
								<li>Fields marked as (*) are mandatory.</li>
								<li>Name should be entered in FULL CAPITAL LETTER.</li>
								<li>First Letter should be in CAPS only.</li>
								<li>You can navigate all the pages before submitting the application by using back option.</li>
								<li>Ensure that all the details are correct before clicking submit button. Changes cannot be done after submission.</li>
								<li>After Submitting the application form, Your application form has been send to the given E-Mail id which is in the application form.</li>
								<li>Take a Printout and Affix your recent Passport Size Photograph and get a sign from Gazetted Officer.</li>
								<li>Ensure that Applicant and Father/Guardian are duly signed in the application form wherever necessary.</li>
								<li>Submit the application form to the college in person.</li>
							</ul>
						</div>	
					</div>	
					</div>
					
					
				</div>
			
		</section>
		
        <Footer></Footer>
        </>
    )
}