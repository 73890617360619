
import './App.css';
import './assets/css/fonts.css';

import HomeScreen from './Components/HomeScreen';
import AboutUsScreen from './Components/AboutUsScreen';
import ChairmanScreen from './Components/ChairmanScreen';
import SecretaryScreen from './Components/SecretaryScreen';
import CooScreen from './Components/CooScreen';
import DirectorsScreen from './Components/DirectorsScreen';
import ArtsScienceScreen from './Components/ArtsScienceScreen';
import CollegeEducationScreen from './Components/CollegeEducationScreen';
import CollegeNursingScreen from './Components/CollegeNursingScreen';
import ParamedicalScreen from './Components/ParamedicalScreen';
import PhysiotherapyScreen from './Components/PhysiotherapyScreen';
import OnlineApplicationScreen from './Components/OnlineApplicationScreen';
import ContactUsScreen from './Components/ContactUsScreen';
import GalleryScreen from './Components/GalleryScreen';

import BedScreen from './Components/BedScreen';

import BscComputerScience from './Components/BscComputerScience';
import BAEnglishScreen from './Components/BAEnglishScreen';
import BAHistoryScreen from './Components/BAHistoryScreen';
import BATamilScreen from './Components/BATamilScreen';
import BCAScreen from './Components/BCAScreen';
import BCOMScreen from './Components/BCOMScreen';
import BscChemistryScreen from './Components/BscChemistryScreen';
import BscMathsScreen from './Components/BscMathsScreen';
import BscNursingScreen from './Components/BscNursingScreen';
import BscRadiologyScreen from './Components/BscRadiologyScreen';

import { Route,Routes } from 'react-router-dom';
import BscPhysicsScreen from './Components/BscPhysicsScreen';
import BscZoologyScreen from './Components/BscZoologyScreen';

import MAEnglishScreen from './Components/MAEnglishScreen';
import MCOMScreen from './Components/MCOMScreen';
import MSCSEScreen from './Components/MSCCSEScreen';

function App() {
  return ( 
      <Routes>
        <Route exact path='/' element={<HomeScreen/>} />
        <Route path='/about-us' element={<AboutUsScreen/>} />   
        <Route path='/chairman' element={<ChairmanScreen/>} />     
        <Route path='/secretary' element={<SecretaryScreen/>} />    
        <Route path='/directors' element={<DirectorsScreen/>} />    
        <Route path='/coo' element={<CooScreen/>} />    
        <Route path='/arts-and-science' element={<ArtsScienceScreen/>} />   
        <Route path='/college-of-education' element={<CollegeEducationScreen/>} />     
        <Route path='/college-of-nursing' element={<CollegeNursingScreen/>} />    
        <Route path='/paramedical' element={<ParamedicalScreen/>} />    
        <Route path='/physiotherapy' element={<PhysiotherapyScreen/>} />        
        <Route path='/online-application' element={<OnlineApplicationScreen/>} />    
        <Route path='/contact-us' element={<ContactUsScreen/>} />    
        <Route path='/gallery' element={<GalleryScreen/>} />     

        <Route path='/b-ed' element={<BedScreen/>} />        
        <Route path='/b-sc-nursing' element={<BscNursingScreen/>} />    
        <Route path='/b-sc-radiology' element={<BscRadiologyScreen/>} />    
        <Route path='/ma-english' element={<MAEnglishScreen/>} />          
        <Route path='/mcom-regular' element={<MCOMScreen/>} />
        <Route path='/msc-computer-science' element={<MSCSEScreen/>} />  
        
        <Route path='/ba-tamil' element={<BATamilScreen/>} />        
        <Route path='/ba-english' element={<BAEnglishScreen/>} />    
        <Route path='/ba-history' element={<BAHistoryScreen/>} />    
        <Route path='/bsc-maths' element={<BscMathsScreen/>} />          
        <Route path='/bsc-physics' element={<BscPhysicsScreen/>} />
        <Route path='/bsc-chemistry' element={<BscChemistryScreen/>} />  
        <Route path='/bsc-zoology' element={<BscZoologyScreen/>} />          
        <Route path='/bsc-computer-science' element={<BscComputerScience/>} />
        <Route path='/bcom-regular' element={<BCOMScreen/>} />                  
        <Route path='/bca' element={<BCAScreen/>} />          

        
                   
      </Routes>
  );
}

export default App;
