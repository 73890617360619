import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import missionImage from '../assets/images/mission-icon.png';
import visionImage from '../assets/images/vision-icon.png';

export default function BCOMScreen(){
    return(
        <>
        <Header></Header>
        <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Department of Commerce and CA</h1>	
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				    <div className="row">					
						<div className="col-12 col-xl-12 col-sm-12">
								<div className="cnt spl-cnt">						    
									<p>The department of commerce was established in the year 2013, the department offers B.Com(CA), B.Com (R ) and M.Com now it has grown into a full pledged department. At present we have two hundred students along with nine faculty members, who are qualified with M.phil, Ph.D specialized in accounting and finance area we focusing on accounting practices, Auditing, Banking and Insurance sector.</p>
								</div>	
						</div>			
					</div>
					<div className="row">
						<div className="col-12 col-xl-4 col-sm-12">
							<div className="vision-bg ml-null">
								<div className="hd"> 
									<h3><img src={visionImage} alt="vision"/>Vision</h3>
								</div>
								<div className="cnt">
									<p>Our department provides a platform for women to acquire knowledge and practical exposure to commerce oriented areas like banking, accounting, tally.</p>
								</div>
							</div>
						</div>
							<div className="col-12 col-xl-8 col-sm-12">							
							<div className="vision-bg ml-null">
								<div className="hd">
									<h3><img src={missionImage} alt="mission" />Mission</h3>
								</div>
								<div className="cnt">
									<ul>
                                    
    <li>To provide a better opportunity for commerce students.</li>
    <li>To train the students with required level of competence for employment in both domestic and global market.</li>
    <li>To provide contextually relevant commerce education.</li>
    <li>To train them with required skills for self employment.</li>

                                    </ul>
								</div>
							</div>
						</div>								
					</div>
                    
				</div>
			
		</section>
        <Footer></Footer>
        </>
    )
}