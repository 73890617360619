import React from "react";
import Header from './Header';
import Footer from './Footer';
import introBannerImage from '../assets/images/intro-banner-img.jpg';
import missionImage from '../assets/images/mission-icon.png';
import visionImage from '../assets/images/vision-icon.png';
export default function BCAScreen(){
    return(
        <>
        <Header></Header>
        <section className="hero-banner-slider" style={{backgroundImage: `url(${introBannerImage})`}}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-xl-8 col-sm-12">
						<div className="hero-banner-intro">
							<h1 className="tt spl-tt">Department of Computer Application</h1>	
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section className="hero-about-us spl-cnt">
			<div className="container-fluid">
				
				    <div className="row">					
						<div className="col-12 col-xl-12 col-sm-12">
								<div className="cnt spl-cnt">						    
									<p>We the department of Computer Application ever Since 2013, strive to create an ambience of academic excellence in which new ideas, research and award flourish and from the leaders and innovators of future emerge. The department is well equipped and organized for its innovative technical education.</p>
								</div>	
						</div>			
					</div>
					<div className="row">
						<div className="col-12 col-xl-4 col-sm-12">
							<div className="vision-bg ml-null">
								<div className="hd"> 
									<h3><img src={visionImage} alt="vision" />Vision</h3>
								</div>
								<div className="cnt">
									<p>The vision of the department is to become a recognized leader in southern part of Tamilnadu offering high-quality undergraduate programs in computing sciences to a large number of talented students at affordable tuition rates.</p>
								</div>
							</div>
						</div>
							<div className="col-12 col-xl-8 col-sm-12">							
							<div className="vision-bg ml-null">
								<div className="hd">
									<h3><img src={missionImage}  alt="mission" />Mission</h3>
								</div>
								<div className="cnt">
									<ul>
                                    
    <li>Commitment to high quality undergraduate education and student success.</li>
    <li>Commitment to widening educational opportunities to women and minority groups underrepresented in the discipline.</li>
    <li>Continuous program improvement through assessment of program educational objectives and student outcomes.</li>
    <li>Quality assurance of academic programs through disciplinary accreditation.</li>
    <li>Service to the college, university, community and discipline.</li>
    <li>Continued professional development of faculty members and scholarly contributions to the often with student participation.</li>
    
                                    </ul>
								</div>
							</div>
						</div>								
					</div>
                    <div className="row">					
						<div className="col-12 col-xl-12 col-sm-12">
								<div className="cnt spl-cnt">	
                                    <h4>Department Highlights:</h4>					    
                                    <ul>
                                        
    <li>During the year 2016, Our 1st batch student (2012-2015) G.Anitha got University second rank.</li>
    <li>Our students won the prizes on the State level Inter-Collegiate meet, Workshop and Paper Presentation held at various colleges.</li>
    <li>We the department. Conducted two Inter-Collegiate meet in the year 2014 & 2016.</li>

                                    </ul>
                                    </div>	
						</div>			
					</div>
				</div>
			
		</section>
        <Footer></Footer>
        </>
    )
}